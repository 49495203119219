import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Seo from '../components/seo'
import Container from '../components/container'
import * as styles from './recipes.module.css'

class RecipesIndex extends React.Component {
  render() {
    const recipes = get(this, 'props.data.allContentfulRecipe.nodes')

    return (
      <Container className={styles.recipe}>
        <Seo title="Recettes" description="Nos recettes à base de tofu."  />
        <h1>Recettes</h1>
        <ul className={styles.recipeList}>
          {recipes.map((recipe) => {
            return (
              <li key={recipe.slug}>
                <Link to={`/recettes/${recipe.slug}`} className={styles.link}>
                  <GatsbyImage alt={recipe.title} image={recipe.heroImage.gatsbyImage} objectFit='cover' />
                  <div className={styles.title}>{recipe.title}</div>
                </Link>
              </li>
            )
          })}
        </ul>
      </Container>
    )
  }
}

export default RecipesIndex

export const pageQuery = graphql`
  query RecipesIndexQuery {
    allContentfulRecipe {
      nodes {
        title
        slug
        heroImage {
          gatsbyImage(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
        description {
          raw
        }
      }
    }
  }
`
